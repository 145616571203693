.stroke-picker {
  &__list {
    list-style: none;
    font-family: sans-serif;
    width: 4.5em;
    max-height: 10.2em;
    overflow-x: hidden;
    padding: 0.3em 0.1em 0;
    margin: auto;
    transition: all 0.3s;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__item {
    box-sizing: border-box;
    font-size: 1em;
    color: #888;
    width: 3.5em;
    cursor: pointer;
    text-align: center;
    border-left: 3px solid transparent;
    padding: 0.4em 0;
    transition: all 0.3s;

    &:not(:first-child) {
      margin-top: 0.2em;
    }

    &--active,
    &:hover,
    &:active {
      color: #444;
      transform: scale(1.05);
      transform-origin: 50% 60%;
      border-left: 3px solid #3d66fd;
      background-color: #efefef;
    }
  }
}
