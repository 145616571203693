.modal {
  &__bg {
    height: 100vh;
    width: 100vw;
    background-color: rgba(#000, 0.8);
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
  }

  &__window {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: #fff;
    max-width: 800px;
    padding: 2em 2.7em;
    border: 1px solid #eaeaea;
    border-radius: 4px;

    opacity: 0.1;
    transform: scale(0.1) translateX(-50%) translateY(-50%);
    transform-origin: 1% 1%;
    transition: all 0.6s ease;

    &--active {
      transform: scale(1) translateX(-50%) translateY(-50%);
      opacity: 1;
    }
  }

  &__close-btn {
    position: absolute;
    top: -0.75em;
    right: -0.75em;
    background-color: #fff;
    height: 3.5em;
    width: 3.5em;
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__close-icon {
    font-size: 2em;
    color: #868686;
    transition: all 0.2s;

    &:hover,
    &:active {
      color: #545454;
    }

    &:hover {
      transform: scale(1.2);
    }

    &:active {
      transform: scale(0.9);
    }
  }
}
