.loading-overlay {
  position: absolute;
  height: calc(100% - 2em);
  width: 100%;
  color: #fff;
  background-color: rgba(#000, 0.4);
  z-index: 1;
  margin: 1em 0;

  display: flex;
  align-items: center;
  justify-content: center;
}
