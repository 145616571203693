.popup-container {
  position: absolute;
  left: 49.5%;
  top: 38%;
  height: 11.3em;
  width: auto;
  max-width: 11.3em;
  line-height: 1;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #eee;
  z-index: 10000;
  padding: 0.5em;
  box-shadow: 0 0.5em 3em rgba(#000, 0.3);
  transition: all 0.5s ease;
  animation: showPopup 0.5s ease;
  animation-fill-mode: forwards;

  &--hide {
    animation: hidePopup 0.5s ease;
    animation-fill-mode: forwards;
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #e2e2e2;
    top: calc(10.2em + 16px);
    left: 35px;
    transform: rotate(180deg);
  }

  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 16px solid #fff;
    top: calc(10.2em - 1px + 16px);
    left: 39px;
    transform: rotate(180deg);
  }
}

@keyframes showPopup {
  0% {
    opacity: 0;
    transform: translateY(3em) translateX(-2em) scale(0);
    transform-origin: bottom;
  }

  100% {
    opacity: 1;
    transform: translateY(0) translateX(0) scale(1);
  }
}

@keyframes hidePopup {
  0% {
    opacity: 1;
    transform: translateY(0) translateX(0) scale(1);
  }

  100% {
    opacity: 0;
    transform: translateY(3em) translateX(-2em) scale(0);
    transform-origin: bottom;
  }
}
