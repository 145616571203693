*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  margin-top: 25px;
}

.to-do-list-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding-top: 3em;
  background-color: #fdfdfd;
  border: 1px solid #eaeaea;
  width: 100%;
  max-width: 600px;
  height: 100%;
  overflow-y: scroll;
}
