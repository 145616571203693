.to-do-item {
  font-family: sans-serif;
  color: #545454;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  margin: 1em;
  padding: 0.9em 0.6em;
  background-color: #fff;
  box-shadow: 0 1em 2.5em rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;

  &__img-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  p,
  img {
    padding-bottom: 1em;
  }

  img {
    padding-top: 1em;
    align-self: center;
  }

  &--done {
    opacity: 0.5;
    background-color: #eee;
  }
}
