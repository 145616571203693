.canvas-buttons {
  max-width: 22em;
  margin: 0 auto;
  font-family: sans-serif;

  &__row {
    display: flex;
    align-items: center;
    padding-bottom: 1em;

    &--1 {
      justify-content: space-between;
      padding-top: 2em;
    }

    &--2 {
      justify-content: space-around;
      padding-top: 1.2em;
    }
  }

  &__column {
    display: flex;
    justify-items: space-between;
    align-items: center;
  }

  &__icon {
    color: #000;
    transition: all 0.3s;
    font-size: 1.6em;
    cursor: pointer;

    &--1 {
      opacity: 0.25;
    }

    &--2 {
      color: #888;
      margin-left: 0.6em;

      &:first-child {
        margin-right: 0.3em;
      }
    }

    &--active {
      opacity: 0.65;
    }
  }

  &__text {
    font-size: 0.8em;
    padding-top: 0.1em;
    letter-spacing: 2px;
    margin-right: -0.5em;
  }

  &__notification {
    color: #999;
    width: 1.7em;
    transform: translateX(-2.5em) translateY(-0.5em);
    font-weight: bold;
    pointer-events: none;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
